import { render, staticRenderFns } from "./MessageTemplatePage.vue?vue&type=template&id=823d5188&scoped=true&"
import script from "./MessageTemplatePage.vue?vue&type=script&lang=js&"
export * from "./MessageTemplatePage.vue?vue&type=script&lang=js&"
import style0 from "./MessageTemplatePage.vue?vue&type=style&index=0&id=823d5188&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "823d5188",
  null
  
)

export default component.exports