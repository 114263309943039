<template>
  <v-container class="general">
    <page-title :title="`New ${messageTemplateType}`">
      <template slot="actions">
        <LanguageSwitcher
          ref="languageSwitcher"
          :all-languages="true"
          :events="true"
          @click="saveMessageTemplate('stay')"
          :loading="isLoading"
        />
      </template>
    </page-title>
    <v-layout :class="{ loading: isLoading }">
      <v-flex xs8 class="pr-5">
        <v-layout row wrap class="mb-5">
          <v-flex xs12 class="sw-h5">{{
            $t("message_template_list_page.name")
          }}</v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="messageTemplate.name"
              v-validate.disable="'required|min:3'"
              :error-messages="errors.collect('name')"
              :data-vv-name="'name'"
              :data-vv-as="$tc('name', 2)"
              :placeholder="
                $t(
                  `message_template_list_page.${messageTemplateType}.name_placeholder`,
                )
              "
              hide-details
            />
            <ErrorMessages :error-messages="errors.collect('name')" />
          </v-flex>
        </v-layout>
        <v-layout v-if="isType('email')" row wrap class="mb-5">
          <v-flex xs12 class="sw-h5">
            <tooltip name="GroupMessageTemplateWizardMessageSubjectInfoText">
              {{ $t("message_template_list_page.subject") }}
            </tooltip>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="messageTemplate.subject"
              v-validate.disable="'required|min:3'"
              :error-messages="errors.collect('subject')"
              data-vv-name="subject"
              :data-vv-as="$tc('subject', 2)"
              :placeholder="
                $t('message_template_list_page.subject_placeholder')
              "
              hide-details
            ></v-text-field>
            <ErrorMessages :error-messages="errors.collect('subject')" />
          </v-flex>
        </v-layout>
        <v-layout v-if="isType('push')" row wrap class="mb-5">
          <v-flex xs12 class="sw-h5">
            <tooltip name="GroupMessageTemplateWizardMessageTitleInfoText">
              {{ $t("message_template_list_page.title") }}
            </tooltip>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="messageTemplate.subject"
              v-validate.disable="'required|min:3'"
              :error-messages="errors.collect('title')"
              data-vv-name="title"
              :data-vv-as="$tc('title', 2)"
              :placeholder="$t('message_template_list_page.title_placeholder')"
              hide-details
            ></v-text-field>
            <ErrorMessages :error-messages="errors.collect('title')" />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex v-if="isType('email') || isType('push')" xs4 class="pl-5">
        <v-card flat class="border-radius sw-primary-bg">
          <v-card-text class="py-5 text-center">
            <MediaManager
              v-model="messageTemplate.message_image"
              :media-type="'images'"
              :label="$t('message_template_list_page.message_image_label')"
            />
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 class="mb-4 sw-h5" :class="{ loading: isLoading }">
        <tooltip name="GroupMessageTemplateWizardMessageBodyInfoText">{{
          $t("message_template_list_page.body")
        }}</tooltip>
      </v-flex>
      <v-flex xs12 class="mb-3" :class="{ loading: isLoading }">
        <QuillEditor
          v-model="messageTemplate.body"
          v-validate.disable="'required'"
          :error-messages="errors.collect('body')"
          :data-vv-name="'body'"
          :data-vv-as="$t('messageBodyAs')"
          :config="editorConfig"
        />
        <ErrorMessages :error-messages="errors.collect('body')" />
      </v-flex>
      <v-flex xs12>
        <v-btn
          v-if="messageTemplate.id"
          round
          large
          class="sw-accent-bg sw-on-accent text-none"
          @click="
            deleteMessageTemplate(messageTemplate.id, messageTemplate.name)
          "
          :loading="isLoading"
          >{{ $t("common.delete") }}</v-btn
        >
        <v-btn
          round
          large
          class="white sw-accent text-none"
          @click="
            $router.push({
              name: 'message_templates',
            })
          "
          >{{ $t("common.cancel") }}</v-btn
        >
        <v-btn
          round
          large
          class="sw-accent-bg sw-on-accent text-none"
          @click="saveMessageTemplate()"
          :loading="isLoading"
          >{{
            !messageTemplate.id ? $t("common.create") : $t("common.save")
          }}</v-btn
        >
      </v-flex>
    </v-layout>
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
  data: () => ({
    isLoading: false,
    messageTemplate: {},
  }),
  computed: {
    messageTemplateType() {
      return this.messageTemplate.type || this.$route.query.type;
    },
    messageTemplateId() {
      return this.$route.params.message_id;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    editorConfig() {
      return {
        toolbar: this.messageTemplateType !== "sms",
        label: "",
        placeholder: "Insert text here",
        style: {
          height: "400px",
          "--primary-color": "#1f363d",
          "--secondary-color": "#3869D4",
        },
      };
    },
  },
  components: {
    LanguageSwitcher,
  },
  watch: {
    messageTemplateId: {
      handler() {
        this.getMessageTemplate();
      },
    },
    appContentLanguage: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;
        this.getMessageTemplate();
      },
    },
  },
  mounted() {
    this.getMessageTemplate();
  },
  methods: {
    isType(type) {
      if (!type || !this.messageTemplateType) {
        return;
      }

      return this.messageTemplateType === type;
    },
    transformForSave(model) {
      return {
        name: model.name,
        subject: model.subject,
        body: model.body,
        type: this.messageTemplateType,
        message_image:
          model.message_image && model.message_image.id
            ? model.message_image.id
            : null,
        is_template: 1,
        lang: this.appContentLanguage,
      };
    },
    async getMessageTemplate() {
      if (!this.messageTemplateId) {
        return;
      }

      try {
        const params = [
          this.messageTemplateId,
          {
            lang: this.appContentLanguage,
            with_attributes: 1,
          },
        ];

        this.isLoading = true;

        const response = await this.$api.messageTemplates.get(...params);

        let messageTemplate = response.data.data;

        if (messageTemplate.message_image) {
          const response = await this.$api.media.get(
            messageTemplate.message_image,
          );
          messageTemplate.message_image = response.data.data || null;
        }

        this.messageTemplate = messageTemplate;

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
          return;
        }
      }
    },
    async createMessageTemplate() {
      const specs = this.transformForSave(this.messageTemplate);

      const response = await this.$api.messageTemplates.create(specs);

      this.$store.dispatch("addNotification", {
        message: `Message created`,
      });

      this.$router.push(
        {
          name: "message_templates_edit",
          params: {
            message_id: response.data.data.id,
          },
        },
        () => {},
      );
    },
    async updateMessageTemplate() {
      const specs = [
        this.messageTemplateId,
        this.transformForSave(this.messageTemplate),
      ];

      await this.$api.messageTemplates.update(...specs);

      this.$store.dispatch("addNotification", {
        message: `Message updated`,
      });
    },
    async saveMessageTemplate(action) {
      const isValid = await this.$validator.validate();

      if (!isValid) return;

      try {
        this.isLoading = true;

        if (!this.messageTemplate.id) {
          await this.createMessageTemplate();
        } else {
          await this.updateMessageTemplate();
        }

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
          return;
        }
      }

      if (action !== "stay") {
        this.$router.push(
          {
            name: "message_templates",
          },
          () => {},
        );
      } else {
        this.$refs.languageSwitcher.open();
      }
    },
    async deleteMessageTemplate(id, title) {
      const isConfirmed = await this.$refs.confirmationDialog.open(
        this.$t("message_template_list_page.message_template_delete_warning", {
          name: title,
        }),
      );

      if (!isConfirmed) return;

      try {
        this.isLoading = true;

        await this.$api.messageTemplates.delete(id);

        this.isLoading = false;

        this.$store.dispatch("addNotification", {
          message: `Message ${title} deleted`,
        });

        this.$router.push(
          {
            name: "message_templates",
          },
          () => {},
        );
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
          return;
        }
      }
    },
  },
};
</script>

<style scoped>
.loading {
  opacity: 0.4;
  transition: all 0.2s;
  pointer-events: none;
}
</style>
